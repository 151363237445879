import React from "react"
import Layout from "../layout"

class PageNotFoundPage extends React.Component {
  render() {
    return (
      <Layout title='404'>
          <h1>404</h1>
          <p>No page here :(</p>
      </Layout>
    )
  }
}

export default PageNotFoundPage
